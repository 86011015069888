
// Variables
$color-pagination: #5b666e;
$color-primary: #2e60ef;
$color-border: #d2dee4;

/* board: Page Style */
.pagination-container{
    @at-root{
        [data-header-type="shop"] + .main .pagination-container{
            .pagination{
                 &--current { color: $color-primary; }
            }
        }
    }
    display: flex; justify-content: center; margin-top: 40px; margin-right: -4px; margin-left: -4px;
    .pagination{
        display: inline-flex; align-items: center; justify-content: center; position: relative; margin: 0 1px; width: 32px; height: 32px;
        text-align: center; font-size: 16px; color: $color-pagination; border-radius: 6px;
        border: 1px solid transparent; cursor: pointer;
        svg { width: auto; height: 12px; fill: $color-pagination; }
        &--current { z-index: 2; color: $color-primary;}
        &--backward { border-color: $color-border; }
        &--prev { margin-right: 15px; border-color: $color-border; }
        &--next { margin-left: 15px; border-color: $color-border; }
        &--forward { border-color: $color-border; }
    }
}
.pagination-container--block{
    .pagination.current { color: #ffffff; background-color: $color-primary; border-color: $color-primary; }
}
@media (min-width:1025px){
    .pagination-container{
        @at-root{
            [data-header-type="shop"] + .main .pagination-container{
                .pagination:not(.current):hover { border-color: $color-primary; }
                .pagination:not(.current):hover svg { fill: $color-primary; }
            }
        }
        .pagination:not(.current):hover { background-color: rgba(0, 0, 0, 0.05);}
        // .pagination:not(.current):hover svg { fill: $color-primary; }
    }
}
@media(max-width:1024px){
	.pagination-container{
        margin-top: 30px;
        .pagination { font-size: 14px; }
    }
}
@media (max-width:576px){
}
