
.info-card {
    border: 1px solid var(--v-grey-lighten4);
    &__content {
        min-height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .info-card {
        border: 0;
        &__content {
            min-height: 56px;
            padding: 12px 20px;
        }
        &__col {
            border-bottom: 1px solid var(--v-grey-lighten4);
        }
    }
    .row {
        .info-card__col:first-child {
            border-bottom-left-radius: 24px;
            border-left: 1px solid var(--v-grey-lighten4);
        }
        .info-card__col:last-child {
            border-bottom-right-radius: 24px;
            border-right: 1px solid var(--v-grey-lighten4);
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
