
.user-card {
    &__avatar {
        width: 60px;
    }
}
@media (min-width: 768px) {
    .user-card {
        &__avatar {
            width: 80px;
        }
    }
}
