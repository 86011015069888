
.v-text-field {
    height: auto !important;
    min-height: 36px;
    ::v-deep {
        .v-input__slot {
            margin-bottom: 0;
        }
        .v-messages__message {
            margin-bottom: 4px;
        }
    }
}
@media (min-width: 768px) {
    .v-text-field {
        min-height: 48px;
    }
}
