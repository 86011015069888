
.page-section {
    position: relative;
}
.mypage-home-background {
    width: 200vw;
    height: 430px;
    position: absolute;
    top: 0;
    left: -50vw;
    z-index: -1;
}
@media (min-width: 768px) {
    .mypage-home-background {
        height: 356px;
    }
}
