
.white-space-pre-line {
    white-space: pre-line;
}
.reservation-avatar {
    width: 60px;
}
.v-data-table {
    > .v-data-table__wrapper {
        > table {
            > thead > tr > th,
            > tbody > tr > td {
                font-size: 14px;
            }
        }
    }
}
@media (min-width: 768px) {
    .reservation-avatar {
        width: 80px;
    }
    .v-data-table {
        > .v-data-table__wrapper {
            > table {
                > thead > tr > th,
                > tbody > tr > td {
                    font-size: 16px;
                }
            }
        }
    }
}
